import React from 'react';

import { Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import logo from '../../../../assets/logo.png';
import TextoBorda from './TextBorda';

const styles = StyleSheet.create({
  pagina: {
    height: '297mm',
    border: '1mm solid black',
    margin: 0,
    padding: 0,
  },
  secao_oferta: {
    height: '18%',
    backgroundColor: 'red',
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    fontSize: '125',
    color: 'yellow',
    fontFamily: 'Roboto-Bold',
    transform: 'scaleY(1.2)',
    borderBottomRightRadius: 500,
    borderBottomLeftRadius: 500,
  },
  texto_oferta: {},
  sessao_produto: {
    height: '18%',
    backgroundColor: 'white',
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    gap: 50,
  },
  texto_produto: {
    marginTop: 5,
    fontSize: '50',
    fontFamily: 'Roboto-Bold',
    transform: 'scaleY(1.4)',
  },
  texto_produto_2: {
    fontSize: '50',
    fontFamily: 'Roboto-Bold',
    transform: 'scaleY(1.4)',
    marginTop: 10,
  },
  sessao_preco: {
    height: '53%',
    //backgroundColor: 'orange',
    width: '100%',
  },
  sessao_adicionais: {
    height: '11%',
    //backgroundColor: 'green',
    width: '100%',

    fontSize: '25',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',

    paddingLeft: 20,
    paddingBottom: 10,
  },
  numeros_parte_inteira: {
    fontSize: '275',
    position: 'absolute',
    top: 55,
    left: 130,
    transform: 'scaleY(2)',
    fontFamily: 'Roboto-Bold',
  },
  numeros_parte_decimal: {
    fontSize: '80',
    position: 'absolute',
    bottom: 60,
    right: 20,
    transform: 'scaleY(2)',
    fontFamily: 'Roboto-Bold',
  },
  unidade_preco: {
    fontSize: '50',
    position: 'absolute',
    top: 10,
    right: 60,
    fontFamily: 'Roboto-Bold',
  },
  rotulo_cifrao: {
    fontSize: '50',
    position: 'absolute',
    bottom: -20,
    left: 90,
    fontFamily: 'Roboto-Bold',
  },
  logo: {
    width: 150,
    height: 'auto',
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
});

function Modelo2Digitos(props) {
  const {
    descricao,
    descricao2,
    precoParteInteira,
    precoParteReal,
    unidade,
    labelCaixa,
    mensagemPesoPadrao,
    header,
  } = props;

  return (
    <Page size="A4" wrap={false}>
      <View style={styles.pagina}>
        <View style={styles.secao_oferta}>
          <TextoBorda color="yellow">{header}</TextoBorda>
        </View>
        <View style={styles.sessao_produto}>
          <Text style={styles.texto_produto}>{descricao}</Text>
          <Text style={styles.texto_produto_2}>{descricao2}</Text>
        </View>
        <View style={styles.sessao_preco}>
          <Text style={styles.numeros_parte_inteira}>{precoParteInteira}</Text>
          <Text
            style={styles.numeros_parte_decimal}
          >{`,${precoParteReal}`}</Text>
          <Text style={styles.unidade_preco}>{unidade}</Text>
          <Text style={styles.rotulo_cifrao}>R$</Text>
        </View>
        <View style={styles.sessao_adicionais}>
          <Text>{mensagemPesoPadrao}</Text>
          <Text>{labelCaixa}</Text>
        </View>
        <Image style={styles.logo} src={logo} />
      </View>
    </Page>
  );
}

export default Modelo2Digitos;
