import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { api } from '../../../services';
import {
  formatMoeda,
  formatPeso,
  formatPreco,
  POR_PESO_FIXO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  POR_UNIDADE,
  TIPO_IMPRESSAO_UN,
  getPrecoUnitarioVerdadeiroCaixa2,
  LABEL_TIPO_CAIXA_CAIXA,
  LABEL_TIPO_CAIXA_FARDO,
  LABEL_TIPO_CAIXA_GALÉIA,
  LABEL_TIPO_CAIXA_SACO,
} from '../../../utils';
import Relatorio from './relatorio';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const mapaLabelTipoCaixa = {
  [LABEL_TIPO_CAIXA_CAIXA]: 'Caixa',
  [LABEL_TIPO_CAIXA_FARDO]: 'Fardo',
  [LABEL_TIPO_CAIXA_GALÉIA]: 'Galéia',
  [LABEL_TIPO_CAIXA_SACO]: 'Saco',
};

const DialogoTrocoInicial = forwardRef(({ handleClose = () => {} }, ref) => {
  const [open, setOpen] = useState(false);
  const [dados, setDados] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseLocal = () => {
    setOpen(false);
    setDados(null);
    handleClose();
  };

  function formatDados(
    descricao,
    preco,
    tipoUnidade,
    peso_medio,
    tipoImpressao,
    unidades_caixa_nivel_1,
    peso_caixa_nivel_1,
    copias,
    referencia_no_cartaz,
    tipo_label_caixa,
  ) {
    const dadosFormatados = { copias };
    const descricoes = descricao.split('\n');

    switch (descricoes.length) {
      case 0:
        dadosFormatados.descricao = '';
        dadosFormatados.descricao2 = '';
        break;
      case 1:
        dadosFormatados.descricao = descricoes[0];
        dadosFormatados.descricao2 = '';
        break;
      default:
        dadosFormatados.descricao = descricoes[0];
        dadosFormatados.descricao2 = descricoes[1];
        break;
    }

    const stringPreco = formatPreco(preco).trim().split(',');
    dadosFormatados.precoParteInteira = stringPreco[0].trim();
    dadosFormatados.precoParteReal = stringPreco[1].trim();

    switch (tipoUnidade) {
      case POR_PESO_FIXO: {
        if (tipoImpressao === TIPO_IMPRESSAO_UN) {
          dadosFormatados.unidade = 'Un';
          const stringPreco = formatPreco(preco * peso_medio)
            .trim()
            .split(',');
          dadosFormatados.precoParteInteira = stringPreco[0]
            .replace('R$:', '')
            .trim();
          dadosFormatados.precoParteReal = stringPreco[1].trim();
          dadosFormatados.mensagemPesoPadrao = `Peso Padrão de ${formatPeso(
            peso_medio,
          )}`;
        } else {
          dadosFormatados.unidade = 'Kg';
          const stringPreco = formatPreco(preco).trim().split(',');
          dadosFormatados.precoParteInteira = stringPreco[0]
            .replace('R$:', '')
            .trim();
          dadosFormatados.precoParteReal = stringPreco[1].trim();
          dadosFormatados.mensagemPesoPadrao = ``;
        }

        if (unidades_caixa_nivel_1) {
          const labelCaixa = mapaLabelTipoCaixa[tipo_label_caixa];
          dadosFormatados.labelCaixa = `${labelCaixa} com ${unidades_caixa_nivel_1} por ${formatMoeda(
            unidades_caixa_nivel_1 * preco * peso_medio,
          )}`;
        } else {
          dadosFormatados.labelCaixa = ``;
        }
        break;
      }
      case POR_UNIDADE: {
        dadosFormatados.unidade = 'Un';
        if (referencia_no_cartaz) {
          dadosFormatados.mensagemPesoPadrao = referencia_no_cartaz;
        } else {
          dadosFormatados.mensagemPesoPadrao = '';
        }
        const stringPreco = formatPreco(preco).trim().split(',');
        dadosFormatados.precoParteInteira = stringPreco[0]
          .replace('R$:', '')
          .trim();
        dadosFormatados.precoParteReal = stringPreco[1].trim();
        if (unidades_caixa_nivel_1) {
          const labelCaixa = mapaLabelTipoCaixa[tipo_label_caixa];
          dadosFormatados.labelCaixa = `${labelCaixa} com ${unidades_caixa_nivel_1} por ${formatMoeda(
            unidades_caixa_nivel_1 * preco,
          )}`;
        } else {
          dadosFormatados.labelCaixa = ``;
        }
        break;
      }
      case POR_PESO_VARIAVEL_SEM_UNIDADE: {
        dadosFormatados.unidade = 'Kg';
        dadosFormatados.mensagemPesoPadrao = '';
        const stringPreco = formatPreco(preco).trim().split(',');
        dadosFormatados.precoParteInteira = stringPreco[0]
          .replace('R$:', '')
          .trim();
        dadosFormatados.precoParteReal = stringPreco[1].trim();
        if (peso_caixa_nivel_1) {
          const labelCaixa = mapaLabelTipoCaixa[tipo_label_caixa];
          dadosFormatados.labelCaixa = `${labelCaixa} de ${formatPeso(
            peso_caixa_nivel_1,
          )} por ${formatMoeda(peso_caixa_nivel_1 * preco)}`;
        } else {
          dadosFormatados.labelCaixa = ``;
        }
        break;
      }
      default:
        break;
    }

    return dadosFormatados;
  }

  async function getDados(itens, header) {
    try {
      const itensFormatados = [];

      for (let i = 0; i < itens.length; i++) {
        const produto = itens[i];
        const preco = getPrecoUnitarioVerdadeiroCaixa2(produto);
        if (produto.descricao_no_cartaz) {
          itensFormatados.push(
            formatDados(
              produto.descricao_no_cartaz,
              preco,
              produto.unidade.tipo,
              produto.peso_medio,
              produto.tipo_impressao,
              produto.unidades_caixa_nivel_1,
              produto.peso_caixa_nivel_1,
              produto.copias,
              produto.referencia_no_cartaz,
              produto.tipo_label_caixa,
            ),
          );
        } else {
          throw `O produto - ${produto.nome} - ID ${produto.id}, não possui a descrição de cartaz registrada!`;
        }
      }

      setDados({
        itens: itensFormatados,
        titulo: `CARUARU FRIOS - CARTAZES - ${moment().format('DD/MM/YYYY')}`,
        header,
      });
    } catch (e) {
      handleCloseLocal();
      enqueueSnackbar(e ? e.toString() : 'ERROR', {
        variant: 'error',
      });
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(itens, header = 'OFERTA') {
      setOpen(true);

      console.log({
        header,
      });

      getDados(itens, header);
    },
  }));

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseLocal}
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        scroll="paper"
        fullScreen
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleCloseLocal}
          style={{ backgroundColor: '#009FD4', color: 'white' }}
        >
          Cartazes dos Preços dos Produtos
        </DialogTitle>
        <DialogContent dividers style={{ padding: 0, height: '100%' }}>
          <div style={{ height: '100%' }}>
            <Box
              display={{ xs: 'block', sm: 'flex' }}
              flexDirection="column"
              marginBottom="0px"
              height="100%"
            >
              <Relatorio open={open} dados={dados} />
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
});

export default DialogoTrocoInicial;
