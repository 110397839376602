import React from 'react';

import { Text, View } from '@react-pdf/renderer';

const borda = 1;

const TextoBorda = ({ children, color }) => (
  <>
    <View>
      <Text
        style={{
          position: 'absolute',
          left: -1 * borda,
          top: -1 * borda,
          color: 'black',
        }}
      >
        {children}
      </Text>
      <Text
        style={{
          position: 'absolute',
          left: 1 * borda,
          top: -1 * borda,
          color: 'black',
        }}
      >
        {children}
      </Text>
      <Text
        style={{
          position: 'absolute',
          left: -1 * borda,
          top: 1 * borda,
          color: 'black',
        }}
      >
        {children}
      </Text>
      <Text
        style={{
          position: 'absolute',
          left: 1 * borda,
          top: 1 * borda,
          color: 'black',
        }}
      >
        {children}
      </Text>
      <Text style={{ color }}>{children}</Text>
    </View>
  </>
);

export default TextoBorda;
