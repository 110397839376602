import React, { useCallback, useState } from 'react';
import { TopToolbar, sanitizeListRestProps, useListContext } from 'react-admin';

import { Box, makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { SelectInput } from '../../Components';
import SearchInputMobills from '../Transacoes/Inputs/SearchInputMobills';

const optionsPix = [
  {
    label: 'Pix à Distância',
    value: 'a_distancia',
  },
  {
    label: 'Pix por QrCode',
    value: 'qrcode',
  },
  {
    label: 'Todos',
    value: 'todos',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    alignItems: 'center',
  },
}));

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  exporter,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  enabledCreate,
  handleCriar = () => {},
  handleOpenFiltros = () => {},
  ...rest
}) => {
  const classes = useStyles();
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;
  const [tipoPix, setTipoPix] = useState('todos');

  const aplicarFiltros = useCallback(
    (search) => {
      if (setFilters) {
        setFilters({ ...filterValues, search }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const aplicarFiltros2 = useCallback(
    (visible) => {
      if (setFilters) {
        setFilters({ ...filterValues, visible }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const aplicarFiltros3 = useCallback(
    (tipoPagamento) => {
      if (setFilters) {
        setFilters({ ...filterValues, tipoPagamento }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleChange = (event) => {
    aplicarFiltros2(event.target.checked);
  };

  return (
    <TopToolbar {...sanitizeListRestProps(rest)} className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        flex={1}
        alignItems="center"
      >
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={filterValues.visible}
                onChange={handleChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Conferido"
          />
        </Box>
        <Box flex={1} mr="0.5em" mt="16px" mb="8px">
          <FormControl
            variant="outlined"
            style={{
              marginTop: '16px',
              backgroundColor: 'white',
            }}
          >
            <InputLabel shrink id="tipo-pix">
              Tipo de Pix
            </InputLabel>
            <SelectInput
              labelId="tipo-pix"
              name="tipo-pix"
              handleEnter={() => {}}
              label="Tipo de Pix"
              handleKey={() => {}}
              value={tipoPix}
              onChange={(value) => {
                aplicarFiltros3(value.target.value);
                setTipoPix(value.target.value);
              }}
              options={optionsPix}
            />
          </FormControl>
        </Box>
        <Box display="flex" alignItems="center">
          <Box marginLeft="15px">
            <SearchInputMobills
              color="red"
              onChange={(search) => aplicarFiltros(search)}
              placeholder="Pesquise por descrição ou valor"
            />
          </Box>
          <Box marginLeft="15px">
            <Tooltip title="Filtrar" placement="top" aria-label="add2">
              <Box
                boxShadow={2}
                onClick={handleOpenFiltros}
                borderRadius="50px"
                display="flex"
                alignItems="center"
                width="44px"
                style={{
                  backgroundColor: 'white',
                  height: '48px',
                  width: '48px',
                }}
              >
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  style={{ height: '48px', width: '48px' }}
                >
                  <FilterListIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
          <Box marginLeft="15px">
            <Tooltip title="Mais opções" placement="top" aria-label="add2">
              <Box
                boxShadow={2}
                borderRadius="50px"
                display="flex"
                alignItems="center"
                width="44px"
                style={{
                  backgroundColor: 'white',
                  height: '48px',
                  width: '48px',
                }}
              >
                <IconButton
                  className={classes.iconButton}
                  aria-label="search"
                  style={{ height: '48px', width: '48px' }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </TopToolbar>
  );
};

export default ListActions;
