import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
  DateField,
  Datagrid,
  ListBase,
  ListToolbar,
  Pagination,
  useListContext,
  useRefresh,
  Title,
} from 'react-admin';

import { Card, Button, Box, makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import CategoriaField from '../Transacoes/Fields/CategoriaField2';
import ContaField from '../Transacoes/Fields/ContaField2';
import DescricaoField from '../Transacoes/Fields/DescricaoField';
import ValorField from '../Transacoes/Fields/ValorField';
import PeriodoInput2 from '../Transacoes/Inputs/PeriodoInput2';
import Actions from './ActionsList';
import DateMobillsInput from './DateInputMobills';
import DialogoChangeStatusTransacao from './DialogoChangeStatusTransacao';
import ActionsField from './Fields/ActionsField';
import StatusField from './Fields/StatusField';
import TipoField from './Fields/TipoField';
import Filter from './Filter';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ padding: '30px 40px 0px' }}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const useStylesDatagrid = makeStyles((theme) => ({
  statusHeader: {
    paddingLeft: '30px',
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  statusCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingLeft: '30px',
  },
  statusContent: {
    flex: 5,
    marginLeft: '30px',
    maxWidth: '100px',
  },
  dataHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  dataCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  descricaoCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  categoriaCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaHeader: {
    flex: 2,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  contaCell: {
    flex: 2,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  valorCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
  },
  actionsHeader: {
    flex: 1,
    backgroundColor: '#f8f8f8',
    padding: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
  actionsCell: {
    flex: 1,
    padding: '10px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    paddingRight: '100px',
  },
}));

const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();

const DataGridLocal = ({ handleEfetivar = () => {}, ...props }) => {
  const classes = useStylesDatagrid();
  const listContext = useListContext();
  const { filterValues } = listContext;
  return (
    <>
      <Datagrid {...props} bulkActionButtons={false}>
        <StatusField
          source="visible"
          label="visible"
          sortable={false}
          headerClassName={classes.statusHeader}
          cellClassName={classes.statusCell}
        />
        <DateField
          source="data_pagamento"
          label="Data"
          headerClassName={classes.dataHeader}
          cellClassName={classes.dataCell}
        />
        <DescricaoField
          source="descricao"
          label="Descrição"
          sortable={false}
          headerClassName={classes.descricaoHeader}
          cellClassName={classes.descricaoCell}
        />
        <CategoriaField
          label="Categoria"
          source="categoria_transacao_2_id"
          sortable={false}
          headerClassName={classes.categoriaHeader}
          cellClassName={classes.categoriaCell}
        />
        <ContaField
          label="Conta"
          source="conta_id"
          sortable={false}
          headerClassName={classes.categoriaHeader}
          cellClassName={classes.categoriaCell}
        />
        <ValorField
          source="valor"
          textAlign="right"
          label="Valor"
          headerClassName={classes.valorHeader}
          cellClassName={classes.valorCell}
        />
        <TipoField
          source="valor"
          textAlign="right"
          label="Tipo"
          headerClassName={classes.valorHeader}
          cellClassName={classes.valorCell}
          sortable={false}
        />
        <ActionsField
          source="lalala"
          handleEfetivar={handleEfetivar}
          textAlign="right"
          label="Ações"
          headerClassName={classes.actionsHeader}
          cellClassName={classes.actionsCell}
          tipo={filterValues.tipo}
          sortable={false}
        />
      </Datagrid>
    </>
  );
};

const useStylesDrawer = makeStyles((theme) => ({
  paper: {
    borderRadius: '20px 0 0 20px',
    width: '33vw',
  },
  indicatorTab: {
    backgroundColor: 'blue',
  },
  tab: {
    color: 'blue',
    '&:selected': {
      color: 'blue',
    },
  },
}));

const DrawerFilters = ({ open, handleCancelar, handleAplicarFiltros }) => {
  const classes = useStylesDrawer();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;
  const [periodo, setPeriodo] = useState({
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  });

  const aplicarFiltros = useCallback(
    (filters) => {
      if (setFilters) {
        setFilters({ ...filterValues, ...filters }, displayedFilters);
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  function initFiltros() {
    if (filterValues.periodo) {
      setPeriodo(filterValues.periodo);
    } else {
      setPeriodo({
        start: startOfMonth(new Date()),
        end: endOfMonth(new Date()),
      });
    }
  }

  useEffect(() => {
    async function getData() {
      initFiltros();
    }
    if (open) {
      getData();
    }
  }, [open]);

  const handleAplicarFiltrosLocal = (index) => {
    handleAplicarFiltros();
    const filterPeriodo = periodo;
    aplicarFiltros({
      periodo: filterPeriodo,
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{}}
      ModalProps={{}}
      classes={{ paper: classes.paper }}
      onClose={handleCancelar}
    >
      <div
        style={{
          height: '12vh',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid #ededed',
        }}
      >
        <div
          style={{ marginLeft: '40px', fontSize: '20px', fontWeight: 'bold' }}
        >
          Filtro de transações
        </div>
      </div>
      <div
        style={{
          height: '71vh',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
            variant="fullWidth"
            classes={{ indicator: classes.indicatorTab }}
          >
            <Tab
              label="NOVO FILTRO"
              classes={{ root: classes.tab, selected: classes.tab }}
            />
            <Tab label="FILTROS SALVOS" />
          </Tabs>
        </Paper>
        <div style={{ overflowY: 'auto' }}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <div style={{ marginBottom: '24px' }}>
              <PeriodoInput2 value={periodo} onChange={setPeriodo} />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            Item Two
          </TabPanel>
        </div>
      </div>
      <div
        style={{
          height: '17vh',
          backgroundColor: 'white',
          borderTop: '1px solid #ededed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 20px',
        }}
      >
        <Button
          style={{ borderRadius: '5em', color: 'blue', fontWeight: 'bold' }}
          onClick={handleCancelar}
        >
          CANCELAR
        </Button>
        <Button
          style={{
            borderRadius: '5em',
            backgroundColor: 'blue',
            color: 'white',
            fontWeight: 'bold',
          }}
          onClick={handleAplicarFiltrosLocal}
        >
          APLICAR FILTROS
        </Button>
      </div>
    </Drawer>
  );
};

const useStyles4 = makeStyles({
  chip: {
    borderColor: (props) => props.color,
    backgroundColor: 'white',
    cursor: 'default',
    color: '#8e8e8e',
    '&:hover': {
      color: (props) => props.color,
      backgroundColor: 'white',
    },
  },
  deleteIcon: {
    color: (props) => props.color,
    cursor: 'pointer',
    '&:hover': {
      color: (props) => props.color,
    },
  },
  chip2: {
    borderColor: '#8e8e8e',
    backgroundColor: 'white',
    cursor: 'default',
    color: '#8e8e8e',
    '&:hover': {
      color: '#8e8e8e',
      backgroundColor: 'white',
    },
  },
  deleteIcon2: {
    color: '#8e8e8e',
    cursor: 'pointer',
    '&:hover': {
      color: '#8e8e8e',
    },
  },
});

const FiltrosLista = () => {
  const classes2 = useStyles4({ color: 'red' });
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;

  const resetarFiltros = useCallback(() => {
    if (setFilters) {
      setFilters(
        {
          ///...filterValues,
          periodo2: { mes: MES_ATUAL, ano: ANO_ATUAL },
          visible: false,
        },
        displayedFilters,
      );
    }
  }, [displayedFilters, filterValues, setFilters]);

  const hasFilters = filterValues.periodo;

  return (
    <>
      {!hasFilters ? (
        <DateMobillsInput />
      ) : (
        <div
          style={{ display: 'flex', alignItems: 'center', margin: '0px 20px' }}
        >
          <div>Filtros:</div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {filterValues.periodo && (
              <div style={{ margin: '4px' }}>
                <Chip
                  classes={{
                    outlined: classes2.chip2,
                    deleteIcon: classes2.deleteIcon2,
                  }}
                  variant="outlined"
                  label={`De ${format(
                    new Date(filterValues.periodo.start),
                    "dd 'de' MMM 'de' yyyy",
                    { locale: ptBR },
                  )} até ${format(
                    new Date(filterValues.periodo.end),
                    "dd 'de' MMM 'de' yyyy",
                    { locale: ptBR },
                  )}`}
                  style={{
                    color: '#8e8e8e',
                    borderColor: '#8e8e8e',
                  }}
                />
              </div>
            )}
          </div>
          <div>
            <IconButton aria-label="delete" onClick={() => resetarFiltros()}>
              <HighlightOffIcon style={{ color: 'blue' }} />
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
};
const MyList = ({ handleOpenFiltros, ...props }) => {
  const refresh = useRefresh();
  const refDialogoStatus = useRef(null);

  const efetivar = (data) => {
    if (refDialogoStatus.current) {
      refDialogoStatus.current.handleOpen(data.record.id);
    }
  };

  const handleClose = () => {
    refresh();
  };

  return (
    <>
      <Title title="Transações em Pix" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions handleOpenFiltros={handleOpenFiltros} {...props} />}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Box flex={1} margin="20px 0">
          <FiltrosLista />
        </Box>
        <DataGridLocal handleEfetivar={efetivar} />
        <TransacoesPagination />
      </Card>
      <DialogoChangeStatusTransacao
        ref={refDialogoStatus}
        handleClose={handleClose}
      />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => {
  const [openDrawerFilters, setOpenDrawerFilters] = useState(false);
  const handleCancelar = () => {
    setOpenDrawerFilters(false);
  };

  const handleAplicarFiltros = () => {
    setOpenDrawerFilters(false);
  };

  const handleOpenFiltros = () => {
    setOpenDrawerFilters(true);
  };

  return (
    <>
      <ListBase {...props}>
        <MyList handleOpenFiltros={handleOpenFiltros} {...props} />
        <DrawerFilters
          open={openDrawerFilters}
          handleCancelar={handleCancelar}
          handleAplicarFiltros={handleAplicarFiltros}
        />
      </ListBase>
    </>
  );
};

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      //filters={<Filter />}
      title="Transações"
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'data_pagamento', order: 'DESC' }}
      pagination={<TransacoesPagination />}
      perPage={50}
      filterDefaultValues={{
        visible: false,
        periodo2: { mes: MES_ATUAL, ano: ANO_ATUAL },
      }}
    />
  </>
);

export default List2;
