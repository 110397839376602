import React, { useRef, useState, useEffect, useContext } from 'react';

import { makeStyles, Box } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';

import {
  DialogoConfirmacao,
  SearchProduto,
  Actions,
  Label,
  LabelEstoque,
  LabelSubtotal,
  // DialogoProduto,
  DialogoProduto2 as DialogoProduto,
} from '../../../..';
import { getUsername, getListAllAPI } from '../../../../../services';
import {
  toStringLotes,
  POR_UNIDADE,
  POR_PESO_FIXO,
  getEstoqueLabel,
  formatMoeda,
  ceil10,
  STATUS_ATIVO,
  POR_PESO_VARIAVEL_SEM_UNIDADE,
  getTotalCompra,
  TIPO_IMPRESSAO_UN,
  TIPO_COMPRA_MERCADORIAS_REVENDA,
  TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2,
  FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3,
} from '../../../../../utils';
// import DialogoCarregarNota from './DialogoCarregarNota';

import {
  LabelTeclasAtalho,
  DialogoDeletarComSenha,
} from '../../../../DialogoEditarVenda/components';
import { useLotes } from '../../hooks';
import { CompraContext } from '../contextCompra';
//import DialogoPrecificacao from './DialogoPrecificacao';
import AppBarNaturezaCompra from '../PreInicioFrenteCaixaCompra/AppBarNaturezaCompra';
import DialogoDetalhes from '../PreInicioFrenteCaixaCompra/DialogoDetalhes';
import AppBar from './AppBar';
import ContainerDialogoPrecificacaoNova from './ContainerDialogoPrecificacaoNova';
import DialogoDetalhesItens from './DialogoDetalhesItens';
import DialogoFiltrosProduto from './DialogoFiltrosProduto';
import DialogoGrafico from './DialogoGrafico';
import DialogoPrecificacao from './DialogoPrecificacaoNova';
import FormLote from './FormLote';
import Tabela from './Tabela2/indexCompra';

/////////////////////////////////////////////////////

function getQuantidades(produto, quantidade, preco_compra) {
  switch (produto.unidade.tipo) {
    case POR_PESO_FIXO:
      return {
        unidades: quantidade,
        peso: quantidade * produto.peso_medio,
        preco_compra,
      };
    case POR_UNIDADE:
      return {
        unidades: quantidade,
        peso: 0,
        preco_compra,
      };
    case POR_PESO_VARIAVEL_SEM_UNIDADE:
      return {
        unidades: 0,
        peso: quantidade,
        preco_compra,
      };
    default:
      return {
        unidades: 0,
        peso: 0,
        preco_compra,
      };
  }
}

function getRepassesItens(item, qtde) {
  const repasses = {
    repasseDoIpi: 0,
    repasseDoFreteUnitario: 0,
    repasseDeSt: 0,
    repasseDeSeguros: 0,
    repasseDeOutrasDespesas: 0,
  };

  if (item) {
    if (item.vIPI) {
      repasses.repasseDoIpi = item.vIPI.value / qtde;
    }

    if (item.vFrete) {
      repasses.repasseDoFreteUnitario = item.vFrete.value / qtde;
    }

    if (item.vICMSST) {
      repasses.repasseDeSt = item.vICMSST.value / qtde;
    }

    if (item.vSeg) {
      repasses.repasseDeSeguros = item.vSeg.value / qtde;
    }

    if (item.vOutro) {
      repasses.repasseDeOutrasDespesas = item.vOutro.value / qtde;
    }
  }

  return repasses;
}

function getTotaisItemNota(item) {
  const totais = {
    pis: 0, // ok
    cofins: 0, // ok
    icms: 0, // ok
    total: 0, // ok
    bc_icms: 0, // ok
    repasse_ipi: 0, // ok
    repasse_st: 0, // ok
    repasse_frete: 0, // ok
    repasse_seguro: 0, // ok
    repasse_outras_despesas: 0, // ok
    desconto_nfe: 0, // ok
    isento: false, // ok
    nItem: '', // ok
    indTot: 1, // ok
  };

  if (item) {
    if (item.nItem) {
      totais.nItem = item.nItem.value;
    }

    if (item.vPIS) {
      totais.pis = item.vPIS.value;
    }

    if (item.vCOFINS) {
      totais.cofins = item.vCOFINS.value;
    }

    if (item.vIPI) {
      totais.repasse_ipi = item.vIPI.value;
    }

    if (item.vICMSST) {
      totais.repasse_st = item.vICMSST.value;
    }

    if (item.vFrete) {
      totais.repasse_frete = item.vFrete.value;
    }

    if (item.vSeg) {
      totais.repasse_seguro = item.vSeg.value;
    }

    if (item.vOutro) {
      totais.repasse_outras_despesas = item.vOutro.value;
    }

    if (item.vDesc) {
      totais.desconto_nfe = item.vDesc.value;
    }

    if (item.vBC) {
      totais.bc_icms = item.vBC.value;
    } else {
      totais.isento = true;
    }

    if (item.vICMS) {
      totais.icms = item.vICMS.value;
    }

    if (item.vProd) {
      totais.total = item.vProd.value;
    }

    if (item.indTot) {
      totais.indTot = item.indTot.value;
    }
  }

  return totais;
}

function getDescontosItens(item, qtde) {
  const descontos = {
    descontoNfe: 0,
  };

  if (item) {
    if (item.vDesc) {
      descontos.descontoNfe = item.vDesc.value / qtde;
    }
  }

  return descontos;
}

function getFatorCaixaria(produto) {
  if (!produto) return 1;
  if (produto.unidade.tipo === POR_UNIDADE && produto.is_fator_conversao_up)
    return 1;
  if (produto.unidade.tipo === POR_PESO_FIXO) return 1;
  switch (produto.fator_conversao_tipo_caixaria) {
    case FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM:
      return 1;
    case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1: {
      if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
        if (produto.peso_caixa_nivel_1) {
          return produto.peso_caixa_nivel_1;
        }
        return 1;
      }
      if (produto.unidades_caixa_nivel_1) {
        return produto.unidades_caixa_nivel_1;
      }
      return 1;
    }

    case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2: {
      if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
        if (!produto.peso_caixa_nivel_1) {
          return 1;
        }
        if (produto.unidades_caixa_nivel_2) {
          return produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2;
        }
        return 1;
      }
      if (!produto.unidades_caixa_nivel_1) {
        return 1;
      }
      if (produto.unidades_caixa_nivel_2) {
        return produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2;
      }
      return 1;
    }

    case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3: {
      if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
        if (!produto.peso_caixa_nivel_1) {
          return 1;
        }
        if (!produto.unidades_caixa_nivel_2) {
          return 1;
        }
        if (produto.unidades_caixa_nivel_3) {
          return (
            produto.peso_caixa_nivel_1 *
            produto.unidades_caixa_nivel_2 *
            produto.unidades_caixa_nivel_3
          );
        }
        return 1;
      }
      if (!produto.unidades_caixa_nivel_1) {
        return 1;
      }
      if (!produto.unidades_caixa_nivel_2) {
        return 1;
      }
      if (produto.unidades_caixa_nivel_3) {
        return (
          produto.unidades_caixa_nivel_1 *
          produto.unidades_caixa_nivel_2 *
          produto.unidades_caixa_nivel_3
        );
      }
      return 1;
    }

    default:
      return 1;
  }
}

function getDadosLoteBonificacao(item, index, preco_compra_presumido) {
  const produto = item.correspondencia;

  const { preco_compra } = getQuantidades(
    item.correspondencia,
    item.qTrib.value,
    item.vUnTrib.value,
  );

  const {
    repasseDoIpi,
    repasseDoFreteUnitario,
    repasseDeSt,
    repasseDeSeguros,
    repasseDeOutrasDespesas,
  } = getRepassesItens(item, item.qTrib.value);

  const { descontoNfe } = getDescontosItens(item, item.qTrib.value);

  let fator_caixaria = 1;

  fator_caixaria = getFatorCaixaria(produto);

  let fator_conversao_importacao_xml_compra = 1;

  if (produto.unidade.tipo === POR_UNIDADE) {
    if (produto.is_fator_conversao_up) {
      if (produto.fator_conversao_importacao_xml_compra) {
        fator_conversao_importacao_xml_compra =
          produto.fator_conversao_importacao_xml_compra;
      }
    }
  }

  let fator_peso_medio = 1;

  if (produto.unidade.tipo === POR_PESO_FIXO) {
    fator_peso_medio = produto.peso_medio;
  }

  const fatorFinal = fator_conversao_importacao_xml_compra / fator_caixaria;

  const preco_compra_final = preco_compra * fatorFinal;

  const custoFinalProduto =
    (preco_compra +
      repasseDoFreteUnitario +
      repasseDeSeguros +
      repasseDeOutrasDespesas -
      descontoNfe +
      repasseDoIpi +
      repasseDeSt) *
    fatorFinal;

  const custoFinalProdutoPresumido =
    preco_compra_presumido +
    (repasseDoFreteUnitario +
      repasseDeSeguros +
      repasseDeOutrasDespesas -
      descontoNfe +
      repasseDoIpi +
      repasseDeSt) *
      fatorFinal;

  let unidades = 0;
  let peso = 0;

  const qtde =
    item.qTrib.value /
    fatorFinal /
    (fator_caixaria !== 1 ? 1 : fator_peso_medio);

  if (produto.unidade.tipo === POR_PESO_FIXO) {
    unidades = qtde;
    peso = unidades * produto.peso_medio;
  } else if (produto.unidade.tipo === POR_UNIDADE) {
    unidades = qtde;
    peso = 0;
  } else {
    unidades = 0;
    peso = qtde;
  }

  return {
    produto,
    peso: produto.unidade.tipo === POR_UNIDADE ? 0 : peso,
    total: getTotalCompra(peso, unidades, custoFinalProduto, produto),
    unidades:
      produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE ? 0 : unidades,
    unitario: preco_compra_final,
    uidd: `${produto.nome}${index + 1}`,
    idIndicadorChange: -1,
    validade: null,
    unitarioImposto: custoFinalProduto,
    cg: 0,
    dadosPrecificacao: null,
    nImpressoes: 0,
    nImpressoesCartazes: 0,
    index,
    validades: [],
    /* totaisFiscais: getTotaisItemNota(item, produto), */
    unitarioPresumido: preco_compra_presumido
      ? preco_compra_presumido
      : preco_compra_final,
    unitarioImpostoPresumido: preco_compra_presumido
      ? custoFinalProdutoPresumido
      : custoFinalProduto,
    totalPresumido: getTotalCompra(
      peso,
      unidades,
      preco_compra_presumido ? custoFinalProdutoPresumido : custoFinalProduto,
      produto,
    ),
    /* referencia_xml: item.nItem.value, */
  };
}

/////////////////////////////////////////////////////

function getTotal(peso, unidades, precoUnitario, produto) {
  if (produto) {
    if (produto.unidade.tipo === POR_UNIDADE) {
      return unidades * precoUnitario;
    }
    return peso * precoUnitario;
  }
  return 0;
}

const useStyles = makeStyles(() => ({
  btn: {
    marginTop: '10px',
    opacity: '0.75',
  },
  header: {
    /* height: '17%', */
  },
  body: {
    /* height: '83%', */
  },
  footer: {
    height: '15vh',
    padding: '10px',
    paddingBottom: '20px',
  },
}));

const FECHAR_COMPRA_ACTION = 'F8';
const CANCELAR_COMPRA_ACTION = 'F4';
const DELETAR_LOTE_ACTION = 'F2';
const CRIAR_PRODUTO_ACTION = 'F9';
const EDITAR_PRODUTO_ACTION = 'F7';
const CARREGAR_NOTA_ACTION = 'F11';
const DETALHES_ACTION = 'DETALHES';
const FILTROS_PRODUTO_ACTION = 'FILTROS_PRODUTO';

function getTotalParcelas(parcelas) {
  let soma = 0;
  for (let i = 0; i < parcelas.length; i += 1) {
    soma += parcelas[i].valor;
  }
  return soma;
}

const FrenteCompra = ({
  irParaTelaInit,
  irParaTelaFinalizarCompra,
  showAtendente = true,
}) => {
  const {
    compra: {
      lotes,
      idEdit,
      fornecedor,
      config,
      numeroCompra,
      parcelas,
      repasse_frete,
      repasse_ipi,
      repasse_outras_despesas,
      repasse_seguros,
      repasse_st,
      //
      subTotalProdutos,
      total_repasse_frete,
      total_repasse_ipi,
      total_repasse_outras_despesas,
      total_repasse_seguros,
      total_repasse_st,
      //
      isProdutosSomenteFornecedor,
      isIncluiInativos,
      //isImportacaoXml,
      tipo,
      subTotalPresumido,
      dadosXml,
      isImportacaoXml,
      total_desconto_descarrego,
      total_desconto_bonificacao,
    },
    dispatch,
  } = useContext(CompraContext);
  const [atendente, setAtendente] = useState('');
  const refDialogoConfirmacao = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoPrecificacao = useRef(null);
  const refDialogoCarregarNota = useRef(null);
  const refDialogoDetalhes = useRef(null);
  const refDialogoDetalhesItens = useRef(null);

  const refDialogoGrafico = useRef(null);

  const refDialogoFiltrosProduto = useRef(null);

  const [showValoresReais, setShowValoresReais] = useState(false);

  const {
    searchHandle,
    addNewItem,
    setSearch,
    produto,
    subTotal,
    refSearch,
    search,
    refSidebarInputs,
    enqueueSnackbar,
    setProduto,
  } = useLotes(
    lotes,
    updateItem,
    repasse_frete,
    repasse_ipi,
    repasse_outras_despesas,
    repasse_seguros,
    repasse_st,
  );

  const classes = useStyles();
  const refDialogoProduto = useRef(null);

  const nomeProduto =
    produto !== null
      ? produto.nome
      : idEdit > 0
      ? 'Editar Compra'
      : 'Nova Compra';

  function updateItem(body) {
    dispatch({
      type: 'ADD_LOTE',
      lote: body,
    });
    if (refSearch.current) refSearch.current.focus();
  }

  async function getAtendente() {
    const username = getUsername();
    setAtendente(username || '');
  }

  useEffect(() => {
    getAtendente();
  }, []);

  function removeItens(indices) {
    dispatch({ type: 'REMOVE_LOTE', indices });
  }

  function handleConfirma(codigo) {
    switch (codigo) {
      case CANCELAR_COMPRA_ACTION:
        irParaTelaInit();
        break;
      default:
        break;
    }
  }

  function openDialogoCarregarNota() {
    if (refDialogoCarregarNota.current) {
      refDialogoCarregarNota.current.handleOpen();
    }
  }

  function handleActions(action) {
    switch (action) {
      case FECHAR_COMPRA_ACTION:
        if (true) {
          irParaTelaFinalizarCompra();
        } else {
          enqueueSnackbar('É necessário ao menos um lote na compra!', {
            variant: 'warning',
          });
        }
        break;
      case CANCELAR_COMPRA_ACTION:
        /*if (refDialogoConfirmacao.current) {
          refDialogoConfirmacao.current.handleOpen(
            'Calcelamento da compra',
            'Tem certeza que deseja cancelar essa compra!',
            CANCELAR_COMPRA_ACTION,
          );
        }*/
        irParaTelaInit();
        break;
      case DELETAR_LOTE_ACTION:
        if (lotes.length > 0) {
          if (refDialogoDeletar.current) {
            refDialogoDeletar.current.handleOpen();
          }
        }
        break;
      case CRIAR_PRODUTO_ACTION:
        // if (refDialogoProduto.current) refDialogoProduto.current.handleOpen(0);
        if (refDialogoProduto.current) refDialogoProduto.current.handleCreate();
        break;
      case EDITAR_PRODUTO_ACTION:
        // if (refDialogoProduto.current) refDialogoProduto.current.handleOpen(1);
        if (refDialogoProduto.current) {
          const idProduto = produto ? produto.id : 0;
          if (idProduto > 0) {
            refDialogoProduto.current.handleEdit(idProduto);
          }
        }
        break;
      case DETALHES_ACTION:
        if (refDialogoDetalhes.current) refDialogoDetalhes.current.handleOpen();
        break;
      case FILTROS_PRODUTO_ACTION:
        if (refDialogoFiltrosProduto.current)
          refDialogoFiltrosProduto.current.handleOpen();
        break;
      /* case CARREGAR_NOTA_ACTION:
        openDialogoCarregarNota();
        break; */
      default:
        break;
    }
  }

  async function updateProduto(idUpdate) {
    try {
      const data = await getListAllAPI(
        'produtos',
        ['id', 'asc'],
        { id: [idUpdate] },
        ['unidade', 'categoria', 'familia'],
      );
      if (data.data.length > 0) {
        setProduto(data.data[0]);
      } else {
        throw 'Este produto não existe!';
      }
    } catch (erros) {
      // nada
    }
  }

  function middlewareSearchHandle(codigo, id) {
    if (codigo || id) {
      if (refDialogoPrecificacao.current)
        refDialogoPrecificacao.current.handleOpen(
          codigo,
          config,
          fornecedor,
          id,
        );
    }
    searchHandle(codigo, id);
  }

  const totalParcelas = getTotalParcelas(parcelas);

  function getUnidade(produto) {
    if (!produto) return '';
    if (produto.unidade.tipo === POR_UNIDADE) return 'Un';
    if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) return 'Kg';
    if (produto.unidade.tipo === POR_PESO_FIXO) {
      if (produto.tipo_impressao === TIPO_IMPRESSAO_UN) {
        return 'Un';
      }
      return 'Kg';
    }
    return '';
  }

  function abrirGrafico(dados) {
    if (refDialogoGrafico.current) {
      //refDialogoGrafico.current.handleOpen(data);
      if (dados.dadosPrecificacao) {
        let fatorPesoMedio = 1;

        const unidadeTrabalhada = getUnidade(dados.dadosOriginais.produto);

        if (dados.dadosOriginais.produto) {
          if (dados.dadosOriginais.produto.unidade.tipo === POR_PESO_FIXO) {
            fatorPesoMedio = dados.dadosOriginais.produto.peso_medio;
          }
        }

        refDialogoGrafico.current.handleOpen(
          [
            {
              label: 'Valor Negociado com a indústria',
              backgroundColor: 'rgba(68, 114, 196, 1)',
              borderColor: 'rgba(68, 114, 196, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.valor_negociado_com_a_industria *
                  fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Margem de Custo Operacional',
              backgroundColor: 'rgba(237, 125, 49, 1)',
              borderColor: 'rgba(237, 125, 49, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.custo_operacional * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Diferença de ICMS',
              backgroundColor: 'rgba(165, 165, 165, 1)',
              borderColor: 'rgba(165, 165, 165, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.diferenca_de_icms * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Lucro Líquido',
              backgroundColor: 'rgba(91, 155, 213, 1)',
              borderColor: 'rgba(91, 155, 213, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.lucro_bruto * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Diferença de COFINS',
              backgroundColor: 'rgba(112, 173, 71, 1)',
              borderColor: 'rgba(112, 173, 71, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.diferenca_de_cofins * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Margem de Custo de Cartão',
              backgroundColor: 'rgba(10, 224, 196, 1)',
              borderColor: 'rgba(10, 224, 196, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.custo_de_cartao * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Diferença de PIS',
              backgroundColor: 'rgba(7, 15, 49, 1)',
              borderColor: 'rgba(7, 15, 49, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.difereca_de_pis * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Repasse do IPI',
              backgroundColor: 'rgba(200, 165, 165, 1)',
              borderColor: 'rgba(200, 165, 165, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.repasse_do_ipi * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Repasse do Frete',
              backgroundColor: 'rgba(90, 1, 213, 1)',
              borderColor: 'rgba(90, 1, 213, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.repasse_do_frete * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Repasse do ST',
              backgroundColor: 'rgba(200, 0, 0, 1)',
              borderColor: 'rgba(200, 0, 0, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.repasse_de_st * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Repasse do Seguro',
              backgroundColor: 'rgba(60, 60, 6, 1)',
              borderColor: 'rgba(60, 60, 6, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.repasse_do_seguro * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Repasse de Outras Despesas',
              backgroundColor: 'rgba(5, 5, 5, 1)',
              borderColor: 'rgba(5, 5, 5, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.repasse_de_outras_despesas *
                  fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'ICMS de Fronteira',
              backgroundColor: 'rgba(100, 50, 100, 1)',
              borderColor: 'rgba(100, 50, 100)',
              valor: ceil10(
                dados.dadosPrecificacao.icms_fronteira * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'ICMS de Antecipação',
              backgroundColor: 'rgba(10, 20, 250, 1)',
              borderColor: 'rgba(10, 20, 250, 1)',
              valor: ceil10(
                dados.dadosPrecificacao.icms_antecipacao * fatorPesoMedio,
                -2,
              ),
            },
            {
              label: 'Desconto Total',
              backgroundColor: 'rgba(37, 205, 200, 1)',
              borderColor: 'rgba(37, 205, 200, 1)',
              valor: ceil10(
                -1 *
                  (dados.dadosPrecificacao.desconto_em_nfe +
                    dados.dadosPrecificacao.desconto_em_boleto +
                    dados.dadosPrecificacao.desconto_em_bonificacao +
                    dados.dadosPrecificacao
                      .desconto_em_backlight_e_ponto_extra +
                    dados.dadosPrecificacao.desconto_em_jornal_de_ofertas +
                    dados.dadosPrecificacao
                      .desconto_em_descargo_de_mercadorias) *
                  fatorPesoMedio,
                -2,
              ),
            },
          ],
          `${formatMoeda(
            dados.dadosPrecificacao.preco_de_venda * fatorPesoMedio,
          )} / ${unidadeTrabalhada}`,
          dados.produto,
        );
      } else {
        enqueueSnackbar(`Este lote não tem dados de precificação salvos!`, {
          variant: 'error',
        });
      }
    }
  }

  function handleDetalhes(dados) {
    if (refDialogoDetalhesItens.current) {
      refDialogoDetalhesItens.current.handleOpen(dados);
    }
  }

  function updateFiltros(isProdutosSomenteFornecedor, isIncluiInativos) {
    dispatch({
      type: 'UPDATE_FILTROS_PESQUISA',
      isProdutosSomenteFornecedor,
      isIncluiInativos,
    });
  }

  function getFiltros() {
    const filters = {};
    if (isProdutosSomenteFornecedor) {
      filters.fornecedor_id = fornecedor.id;
    }
    if (!isIncluiInativos) {
      filters.status = STATUS_ATIVO;
    }
    return filters;
  }

  function distribuiRepasses() {}

  function reprecificar({
    valor_negociado_com_a_industria,
    repasse_do_ipi,
    repasse_do_frete,
    repasse_de_st,
    repasse_do_seguro,
    repasse_de_outras_despesas,
    pis_compra,
    cofins_compra,
    icms_compra,
    taxa_icms_venda,
    taxa_pis_venda,
    taxa_cofins_venda,
    taxa_lucro_bruto,
    taxa_custo_operacional,
    taxa_custo_de_cartao,
  }) {
    //
  }

  function atualizarItemExistente(body) {
    dispatch({
      type: 'UPDATE_LOTE',
      lote: body,
    });
    if (refSearch.current) refSearch.current.focus();
  }

  function getQuantidade(dados) {
    switch (dados.produto.unidade.tipo) {
      case POR_UNIDADE:
        return dados.unidades;
      case POR_PESO_FIXO:
        return dados.unidades;
      case POR_PESO_VARIAVEL_SEM_UNIDADE:
        return dados.peso;
      default:
        return dados.peso;
    }
  }

  function format10(value) {
    return value < 10 ? `0${value}` : `${value}`;
  }

  function formatDate(date) {
    if (date) {
      const dateReal = new Date(date);
      const ano = dateReal.getFullYear();
      const mes = format10(dateReal.getMonth() + 1);
      const dia = format10(dateReal.getDate());
      return `${ano}-${mes}-${dia}`;
    }
    return '';
  }

  function handleEditarPrecificacao(dados2, index) {
    const dados = dados2;

    //const dados = dados2.dadosOriginais;
    if (refDialogoPrecificacao.current) {
      const quantidade = getQuantidade(dados);
      refDialogoPrecificacao.current.handleEdit(
        {
          produtoId: dados.produto.id,
          config,
          fornecedor,
          validade: formatDate(dados.validade),
          nImpressoes: dados.nImpressoes,
          valorNegociadoComAIndustria: dados.unitario,
          quantidadeComprada: quantidade,
          //
          repasseDoIpi: 0,
          repasseDoFreteUnitario: 0,
          repasseDeSt: 0,
          repasseDeSeguros: 0,
          repasseDeOutrasDespesas: 0,
          descontoNfe: 0,
          descontoBoleto: 0,
          descontoBonificacao: 0,
          descontoBacklight: 0,
          descontoJornalOfertas: 0,
          descontoDescargoMercadorias: 0,

          /* repasseDoIpi: dados.dadosPrecificacao.repasse_do_ipi,
          repasseDoFreteUnitario: dados.dadosPrecificacao.repasse_do_frete,
          repasseDeSt: dados.dadosPrecificacao.repasse_de_st,
          repasseDeSeguros: dados.dadosPrecificacao.repasse_do_seguro,
          repasseDeOutrasDespesas:
            dados.dadosPrecificacao.repasse_de_outras_despesas,
          index,
          descontoNfe: dados.dadosPrecificacao.desconto_em_nfe,
          descontoBoleto: dados.dadosPrecificacao.desconto_em_boleto,
          descontoBonificacao: dados.dadosPrecificacao.desconto_em_bonificacao,
          descontoBacklight:
            dados.dadosPrecificacao.desconto_em_backlight_e_ponto_extra,
          descontoJornalOfertas:
            dados.dadosPrecificacao.desconto_em_jornal_de_ofertas,
          descontoDescargoMercadorias:
            dados.dadosPrecificacao.desconto_em_descargo_de_mercadorias, */
          dadosComplementaresNota: null,
          validades: dados.validades,
          index,
        },
        dados.produto,
        dados,
      );
    }
  }

  function getFatorCaixaria(produto) {
    if (!produto) return 1;
    if (produto.unidade.tipo === POR_UNIDADE && produto.is_fator_conversao_up)
      return 1;
    if (produto.unidade.tipo === POR_PESO_FIXO) return 1;
    switch (produto.fator_conversao_tipo_caixaria) {
      case FATOR_CONVERSAO_XML_COMPRA_TIPO_NENHUM:
        return 1;
      case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_1: {
        if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
          if (produto.peso_caixa_nivel_1) {
            return produto.peso_caixa_nivel_1;
          }
          return 1;
        }
        if (produto.unidades_caixa_nivel_1) {
          return produto.unidades_caixa_nivel_1;
        }
        return 1;
      }

      case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_2: {
        if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
          if (!produto.peso_caixa_nivel_1) {
            return 1;
          }
          if (produto.unidades_caixa_nivel_2) {
            return produto.peso_caixa_nivel_1 * produto.unidades_caixa_nivel_2;
          }
          return 1;
        }
        if (!produto.unidades_caixa_nivel_1) {
          return 1;
        }
        if (produto.unidades_caixa_nivel_2) {
          return (
            produto.unidades_caixa_nivel_1 * produto.unidades_caixa_nivel_2
          );
        }
        return 1;
      }

      case FATOR_CONVERSAO_XML_COMPRA_TIPO_CAIXARIA_NIVEL_3: {
        if (produto.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE) {
          if (!produto.peso_caixa_nivel_1) {
            return 1;
          }
          if (!produto.unidades_caixa_nivel_2) {
            return 1;
          }
          if (produto.unidades_caixa_nivel_3) {
            return (
              produto.peso_caixa_nivel_1 *
              produto.unidades_caixa_nivel_2 *
              produto.unidades_caixa_nivel_3
            );
          }
          return 1;
        }
        if (!produto.unidades_caixa_nivel_1) {
          return 1;
        }
        if (!produto.unidades_caixa_nivel_2) {
          return 1;
        }
        if (produto.unidades_caixa_nivel_3) {
          return (
            produto.unidades_caixa_nivel_1 *
            produto.unidades_caixa_nivel_2 *
            produto.unidades_caixa_nivel_3
          );
        }
        return 1;
      }

      default:
        return 1;
    }
  }

  function getDadosXmlItem(n) {
    if (dadosXml) {
      const itens = dadosXml.itens;
      const dados = itens.find((item) => item.nItem.value === n);
      if (dados) {
        return dados;
      }
      return null;
    }

    return null;
  }

  function getDadosPrecificacao(dados2, index) {
    const dados = dados2;

    if (!dados) {
      return null;
    }

    if (!dados2.referencia_xml) {
      return null;
    }

    const item = getDadosXmlItem(dados2.referencia_xml);

    if (!item) {
      return null;
    }

    const dadosPrecificacao = dados.dadosPrecificacao;

    if (!dadosPrecificacao) {
      return null;
    }

    const { produto, nImpressoes, validades } = dados;

    const {
      repasse_do_ipi,
      repasse_do_frete,
      repasse_de_st,
      repasse_do_seguro,
      repasse_de_outras_despesas,
      desconto_em_nfe,
      desconto_em_boleto,
      desconto_em_bonificacao,
      desconto_em_backlight_e_ponto_extra,
      desconto_em_jornal_de_ofertas,
      desconto_em_descargo_de_mercadorias,
      taxa_lucro_bruto,
      valor_negociado_com_a_industria_presumido,
      valor_negociado_com_a_industria,
    } = dadosPrecificacao;

    let fator_caixaria = 1;

    fator_caixaria = getFatorCaixaria(produto);

    let fator_conversao_importacao_xml_compra = 1;

    if (produto.unidade.tipo === POR_UNIDADE) {
      if (produto.is_fator_conversao_up) {
        if (produto.fator_conversao_importacao_xml_compra) {
          fator_conversao_importacao_xml_compra =
            produto.fator_conversao_importacao_xml_compra;
        }
      }
    }

    let fator_peso_medio = 1;

    if (produto.unidade.tipo === POR_PESO_FIXO) {
      fator_peso_medio = produto.peso_medio;
    }

    const fatorFinal = fator_conversao_importacao_xml_compra / fator_caixaria;

    return {
      produto,
      repasseDoIpi: repasse_do_ipi,
      repasseDoFreteUnitario: repasse_do_frete,
      repasseDeSt: repasse_de_st,
      repasseDeSeguros: repasse_do_seguro,
      repasseDeOutrasDespesas: repasse_de_outras_despesas,
      descontoNfe: desconto_em_nfe,
      descontoBoleto: desconto_em_boleto,
      descontoBonificacao: desconto_em_bonificacao,
      descontoBacklight: desconto_em_backlight_e_ponto_extra,
      descontoJornalOfertas: desconto_em_jornal_de_ofertas,
      descontoDescargoMercadorias: desconto_em_descargo_de_mercadorias,
      validades,
      valorNegociadoComAIndustria: valor_negociado_com_a_industria,
      valorNegociadoComAIndustriaPresumido: valor_negociado_com_a_industria_presumido,
      nImpressoes,
      margemLucroBruto: taxa_lucro_bruto,
      correspondencia: produto,
      dadosFormatados: dados,
      quantidade:
        item.qTrib.value /
        fatorFinal /
        (fator_caixaria !== 1 ? 1 : fator_peso_medio),
      dadosComplementaresNota: {
        qCom: item.qTrib.value,
        vUnCom: item.vUnTrib.value,
        uCom: item.uTrib.value,
        fator_caixaria,
        fator_conversao_importacao_xml_compra,
        fator_peso_medio,
        vIPI: item.vIPI ? item.vIPI.value : 0,
        vFrete: item.vFrete ? item.vFrete.value : 0,
        vICMSST: item.vICMSST ? item.vICMSST.value : 0,
        vSeg: item.vSeg ? item.vSeg.value : 0,
        vOutro: item.vOutro ? item.vOutro.value : 0,
        vDesc: item.vDesc ? item.vDesc.value : 0,
        //
        quantidadeCompradaFormatado: `${item.qCom.value} ${item.uCom.value} = ${item.qTrib.value} ${item.uTrib.value}`,
        valorUnitarioComprado: `${item.vUnCom.value} / ${item.uCom.value} = ${item.vUnTrib.value} / ${item.uTrib.value}`,
        //
        vProd: item.vProd ? item.vProd.value : 0,
      },
    };
  }

  function handleRePrecificar(dados2, index) {
    if (refDialogoPrecificacao.current) {
      const dados = getDadosPrecificacao(
        dados2.dadosOriginais,
        dados2.dadosOriginais.index,
      );
      if (dados) {
        refDialogoPrecificacao.current.handleEditarDiferente(
          {
            index,
            produto: dados.produto,
            repasseDoIpi: dados.repasseDoIpi,
            repasseDoFreteUnitario: dados.repasseDoFreteUnitario,
            repasseDeSt: dados.repasseDeSt,
            repasseDeSeguros: dados.repasseDeSeguros,
            repasseDeOutrasDespesas: dados.repasseDeOutrasDespesas,
            descontoNfe: dados.descontoNfe,
            descontoBoleto: dados.descontoBoleto,
            descontoBonificacao: dados.descontoBonificacao,
            descontoBacklight: dados.descontoBacklight,
            descontoJornalOfertas: dados.descontoJornalOfertas,
            descontoDescargoMercadorias: dados.descontoDescargoMercadorias,
            dadosComplementaresNota: dados.dadosComplementaresNota,
            validades: dados.validades,
            config,
            fornecedor,
            valorNegociadoComAIndustria: dados.valorNegociadoComAIndustria,
            quantidadeComprada: dados.quantidade,
            valorNegociadoComAIndustriaPresumido:
              dados.valorNegociadoComAIndustriaPresumido,
            nImpressoes: dados.nImpressoes,
            margemLucroBruto: dados.margemLucroBruto,
          },
          dados.correspondencia,
          dados.dadosFormatados,
        );
      } else {
        handleEditarPrecificacao(dados2.dadosOriginais, index);
      }
    }
  }

  function verificaDiferencaNula(v1, v2, config = {}) {
    const configDefault = { diferencaAlvo: 0, tolerancia: 0.01 };

    const configFinal = { ...configDefault, ...config };

    const { diferencaAlvo, tolerancia } = configFinal;

    const diferencaCalculada = Math.abs(v1 - v2);
    if (
      diferencaCalculada >= diferencaAlvo - tolerancia &&
      diferencaCalculada <= diferencaAlvo + tolerancia
    )
      return true;
    return false;
  }

  function getRestanteDescontoDescarregoRealMensagem() {
    let totalUsado = 0;
    for (let i = 0; i < lotes.length; i++) {
      let total = 0;
      if (lotes[i].dadosPrecificacao) {
        total = getTotalCompra(
          lotes[i].peso,
          lotes[i].unidades,
          lotes[i].dadosPrecificacao.desconto_em_descargo_de_mercadorias,
          lotes[i].produto,
        );
      }
      totalUsado += total;
    }

    if (!verificaDiferencaNula(total_desconto_descarrego, totalUsado)) {
      return (
        <div
          style={{
            textAlign: 'center',
            color: 'red',
            margin: '20px 0px',
            fontWeight: 'bold',
          }}
        >{`* O Desconto de Descarrego Usado (${formatMoeda(
          totalUsado,
        )}) é diferente do Desconto de Descarrego Disponível (${formatMoeda(
          total_desconto_descarrego,
        )}), ainda falta usar ${formatMoeda(
          total_desconto_descarrego - totalUsado,
        )}`}</div>
      );
    }

    return null;
  }

  const mensagemErroDescontoDescarrego = getRestanteDescontoDescarregoRealMensagem();

  function getRestanteDescontoDescarrego(index) {
    let totalUsado = 0;
    for (let i = 0; i < lotes.length; i++) {
      let total = 0;
      if (lotes[i].uidd !== index) {
        if (lotes[i].dadosPrecificacao) {
          total = getTotalCompra(
            lotes[i].peso,
            lotes[i].unidades,
            lotes[i].dadosPrecificacao.desconto_em_descargo_de_mercadorias,
            lotes[i].produto,
          );
        }
      }
      totalUsado += total;
    }

    return total_desconto_descarrego - totalUsado;
  }

  function getRestanteDescontoBonificacao(index) {
    let totalUsado = 0;
    for (let i = 0; i < lotes.length; i++) {
      let total = 0;
      if (lotes[i].uidd !== index) {
        if (lotes[i].dadosPrecificacao) {
          total = getTotalCompra(
            lotes[i].peso,
            lotes[i].unidades,
            lotes[i].dadosPrecificacao.desconto_em_bonificacao,
            lotes[i].produto,
          );
        }
      }
      totalUsado += total;
    }

    return total_desconto_bonificacao - totalUsado;
  }

  function getErrosAdicionais(index, descontoDescarrego, descontoBonificacao) {
    const erros = [''];
    const restante = getRestanteDescontoDescarrego(index);
    const desconto2 = Number.isNaN(descontoDescarrego) ? 0 : descontoDescarrego;
    if (desconto2 > restante) {
      /*  erros[0] = `O desconto de descarrego aplicado (${formatMoeda(
            desconto,
          )}) é maior que o desconto de descarrego disponível (${formatMoeda(
            restante,
          )})`; */
      erros[0] = (
        <div>{`* O Desconto de Descarrego Aplicado (${formatMoeda(
          desconto2,
        )}) é maior que o Desconto de Descarrego Disponível (${formatMoeda(
          restante,
        )})`}</div>
      );
    }

    const restanteBonificacao = getRestanteDescontoBonificacao(index);
    const descontoBonificacao2 = Number.isNaN(descontoBonificacao)
      ? 0
      : descontoBonificacao;

    if (descontoBonificacao2 > restanteBonificacao) {
      /*  erros[0] = `O desconto de descarrego aplicado (${formatMoeda(
                        desconto,
                      )}) é maior que o desconto de descarrego disponível (${formatMoeda(
                        restante,
                      )})`; */
      erros[1] = (
        <div>{`* O Desconto de Bonificação Aplicado (${formatMoeda(
          descontoBonificacao2,
        )}) é maior que o Desconto de Bonificação Disponível (${formatMoeda(
          restanteBonificacao,
        )})`}</div>
      );
    }

    return erros;
  }

  function getInformacoesAdicionais(
    index,
    descontoDescarrego,
    descontoBonificacao,
  ) {
    const infos = [''];
    const restante = getRestanteDescontoDescarrego(index);

    const desconto2 = Number.isNaN(descontoDescarrego) ? 0 : descontoDescarrego;

    const diferenca = restante - desconto2;
    if (diferenca <= 0) {
      /*  infos[0] = (
            <div style={{ fontSize: '20px', fontWeight: 'bold', color: 'green' }}>
              Nenhum Desconto de Descarrego Disponível!
            </div>
          ); */
    } else {
      infos[0] = (
        <div
          style={{ fontSize: '20px', fontWeight: 'bold', color: 'green' }}
        >{`O Desconto de Descarrego disponível é de ${formatMoeda(
          diferenca,
        )}`}</div>
      );
    }

    const restanteBonificacao = getRestanteDescontoBonificacao(index);

    const descontoBonificacao2 = Number.isNaN(descontoBonificacao)
      ? 0
      : descontoBonificacao;

    const diferencaBonificacao = restanteBonificacao - descontoBonificacao2;
    if (diferencaBonificacao <= 0) {
      /*  infos[0] = (
                      <div style={{ fontSize: '20px', fontWeight: 'bold', color: 'green' }}>
                        Nenhum Desconto de Descarrego Disponível!
                      </div>
                    ); */
    } else {
      infos[1] = (
        <div
          style={{ fontSize: '20px', fontWeight: 'bold', color: 'green' }}
        >{`O Desconto de Bonificação disponível é de ${formatMoeda(
          descontoBonificacao2,
        )}`}</div>
      );
    }

    return infos;
  }

  return (
    <>
      <AppBar
        disabledBack={false}
        disabledForward={!(lotes.length > 0) || mensagemErroDescontoDescarrego}
        disabledDeleteLote={lotes.length === 0 || isImportacaoXml}
        //disabledCriarProduto={isImportacaoXml}
        disabledEditarProduto={!produto /*|| isImportacaoXml*/}
        //disabledFiltrosProduto={isImportacaoXml}
        onClick={handleActions}
        FECHAR_COMPRA_ACTION={FECHAR_COMPRA_ACTION}
        CANCELAR_COMPRA_ACTION={CANCELAR_COMPRA_ACTION}
        DELETAR_LOTE_ACTION={DELETAR_LOTE_ACTION}
        CRIAR_PRODUTO_ACTION={CRIAR_PRODUTO_ACTION}
        EDITAR_PRODUTO_ACTION={EDITAR_PRODUTO_ACTION}
        numeroCompra={numeroCompra}
        idEdit={idEdit}
        subtotal={subTotal}
        resto={subTotal - totalParcelas}
        totalParcelas={totalParcelas}
        DETALHES_ACTION={DETALHES_ACTION}
        FILTROS_PRODUTO_ACTION={FILTROS_PRODUTO_ACTION}
      />
      <AppBarNaturezaCompra
        tipo={tipo}
        subTotalPresumido={subTotalPresumido}
        handleShowValoresReais={() => {
          setShowValoresReais(!showValoresReais);
        }}
        showValoresReais={showValoresReais}
        visibleInput={tipo === TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA}
        visibleSubTotalPresumido={
          tipo === TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA
        }
      />
      {!isImportacaoXml ? (
        <Box padding="10px" className={classes.header}>
          {/*<Box margin="0px 0px 10px">
          <Label
            label={`SUBTOTAL: ${formatMoeda(subTotal)}`}
          />
  </Box>*/}
          <SearchProduto
            label="Pesquisar produto"
            value={search}
            onChange={(e) => setSearch(e)}
            fullwidth
            searchHandle={middlewareSearchHandle}
            ref={refSearch}
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            //filters={{ ativo: true, fornecedor_id: fornecedor.id }}
            filters={getFiltros()}
            mantemFiltroPadrao={false}
            //disabled={isImportacaoXml}
            disabled={isImportacaoXml}
          />
        </Box>
      ) : null}
      <div>{mensagemErroDescontoDescarrego}</div>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="10px"
        className={classes.body}
      >
        {/*<Box flex={1.5} display="flex" flexDirection="column">
          <div style={{ marginBottom: '15px' }}>
            <Actions
              opcoes={[
                {
                  text: `Voltar (${CANCELAR_COMPRA_ACTION})`,
                  value: CANCELAR_COMPRA_ACTION,
                  hide: false,
                  disabled: false,
                },
                {
                  text: `Fechar(${FECHAR_COMPRA_ACTION})`,
                  value: FECHAR_COMPRA_ACTION,
                  hide: false,
                },
                {
                  text: `Del. Lote (${DELETAR_LOTE_ACTION})`,
                  value: DELETAR_LOTE_ACTION,
                  hide: false,
                  disabled: !(lotes.length > 0),
                },
                {
                  text: `Novo Prod. (${CRIAR_PRODUTO_ACTION})`,
                  value: CRIAR_PRODUTO_ACTION,
                  hide: false,
                  disabled: false,
                },
                {
                  text: `Editar Prod. (${EDITAR_PRODUTO_ACTION})`,
                  value: EDITAR_PRODUTO_ACTION,
                  hide: false,
                  disabled: !produto,
                },
              ]}
              onClick={handleActions}
            />
          </div>

          {produto && (
            <LabelSubtotal valor={subTotal} isOpaco={produto !== null} />
          )}
          {produto && <LabelEstoque produto={produto} />}
          </Box>*/}
        {lotes.length > 0 ? (
          <Box padding="0 0px" flex={4}>
            <Tabela
              handleGrafico={abrirGrafico}
              handleDetalhes={handleDetalhes}
              rows={toStringLotes(lotes)}
              handleEditar={handleRePrecificar}
              headCells={[
                {
                  field: 'numeroLote',
                  label: 'Cód.',
                },
                /*  {
                field: 'produto',
                label: 'Produto',
              }, */
                {
                  field: 'validade',
                  label: 'Validade',
                  right: true,
                },
                {
                  field: 'unidades',
                  label: 'Unidades',
                  right: true,
                },
                {
                  field: 'peso',
                  label: 'Peso (Kg)',
                  right: true,
                },
                /*      {
                  field: 'unitario',
                  label: 'Unitário',
                  right: true,
                }, */
                ...(!showValoresReais
                  ? [
                      {
                        field: 'unitario',
                        label: 'Unitário',
                        right: true,
                      },
                    ]
                  : [
                      {
                        field: 'unitarioPresumido',
                        label: 'Unitário',
                        right: true,
                      },
                    ]),
                /*      {
                  field: 'unitarioImposto',
                  label: 'Unitário Imposto',
                  right: true,
                }, */
                ...(!showValoresReais
                  ? [
                      {
                        field: 'unitarioImposto',
                        label: 'Unitário Imposto',
                        right: true,
                      },
                    ]
                  : [
                      {
                        field: 'unitarioImpostoPresumido',
                        label: 'Unitário Imposto',
                        right: true,
                      },
                    ]),
                ...(tipo === TIPO_COMPRA_MERCADORIAS_REVENDA ||
                tipo === TIPO_COMPRA_BONIFICACAO_MERCADORIAS_REVENDA
                  ? [
                      {
                        field: 'precoVenda',
                        label: 'Precificação',
                        right: true,
                      },
                    ]
                  : []),
                {
                  field: 'nImpressoes',
                  label: 'Nº Impressões',
                  right: true,
                },
                /*{
                  field: 'cg',
                  label: 'CG',
                  right: true,
                },*/
                ...(!showValoresReais
                  ? [
                      {
                        field: 'total',
                        label: 'SubTotal',
                        right: true,
                      },
                    ]
                  : [
                      {
                        field: 'totalPresumido',
                        label: 'SubTotal',
                        right: true,
                      },
                    ]),
              ]}
              tipo={tipo}
            />
          </Box>
        ) : null}
        {/*<Box flex={1.5} display="flex" flexDirection="column" overflow="auto">
          <FormLote
            handleKey={(keyCode, keyName) => {
              handleActions(keyName);
            }}
            handleNewItem={addNewItem}
            disabledSubmit={produto === null}
            ref={refSidebarInputs}
            produto={produto}
          />
          {!produto && (
            <LabelSubtotal valor={subTotal} isOpaco={produto === null} />
          )}
          </Box>*/}
      </Box>
      <DialogoConfirmacao
        ref={refDialogoConfirmacao}
        handleConfirma={handleConfirma}
      />
      <DialogoProduto
        ref={refDialogoProduto}
        /* handleCriarProduto={(produtoAtualizado) => {
          setProduto(produtoAtualizado);
        }}
        handleCloseDialogo={() => {
          if (refSidebarInputs.current) refSidebarInputs.current.focus();
        }}
        produto={produto} */
        handleClose={(idUpdate) => {
          if (refSidebarInputs.current) refSidebarInputs.current.focus();
          if (idUpdate > 0) {
            updateProduto(idUpdate);
          }
        }}
        isImprimir={false}
      />
      {/* <DialogoDeletarItemTabela
        ref={refDialogoDeletar}
        itens={lotes}
        labelResource="Lotes"
        handleDelete={(indices) => removeItens(indices)}
        headerDialogo={(
          <div style={{
            display: 'flex', justifyContent: 'space-between', paddingLeft: '56px', marginBottom: '20px', color: 'red',
          }}
          >
            <div style={{ flex: 3 }}>
              Produto
            </div>
            <div style={{ flex: 1 }}>
              Unidades
            </div>
            <div style={{ flex: 1 }}>
              Peso
            </div>
            <div style={{ flex: 1 }}>
              Unitário
            </div>
            <div style={{ flex: 1 }}>
              Unitário Imposto
            </div>
            <div style={{ flex: 1 }}>
              Validade
            </div>
            <div style={{ flex: 1 }}>
              CG
            </div>
            <div style={{ flex: 1 }}>
              SubTotal
            </div>
          </div>
            )}
        getListItemContent={(value, labelId) => (
          <ListItemText
            id={labelId}
            primary={(
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 4 }}>
                  {value.produto.nome}
                </div>
                <div style={{ flex: 1 }}>
                  {disableUnidades(value.produto) ? value.unidades : '-'}
                </div>
                <div style={{ flex: 1 }}>
                  {disablePeso(value.produto) ? formatPeso(value.peso) : '-'}
                </div>
                <div style={{ flex: 1 }}>
                  {formatMoeda(value.unitario)}
                </div>
                <div style={{ flex: 1 }}>
                  {formatMoeda(value.unitarioImposto)}
                </div>
                <div style={{ flex: 1 }}>
                  {formatMoeda(value.cg)}
                </div>
                <div style={{ flex: 1 }}>
                  {moment(new Date(value.validade)).format('DD/MM/YYYY')}
                </div>
                <div style={{ flex: 1 }}>
                  {formatMoeda(value.total)}
                </div>
              </div>
              )}
          />
        )}
      /> */}

      <DialogoDeletarComSenha
        ref={refDialogoDeletar}
        itens={lotes}
        enqueueSnackbar={enqueueSnackbar}
        handleCancelar={() => {}}
        handleSalvar={(numeroItem) => {
          removeItens([lotes[numeroItem - 1].uidd]);
        }}
        title="Autorização para remover item!"
        usarSenha={false}
      />
      <ContainerDialogoPrecificacaoNova
        getErrosAdicionais={getErrosAdicionais}
        getInformacoesAdicionais={getInformacoesAdicionais}
        ref={refDialogoPrecificacao}
        estoque={getEstoqueLabel(produto)}
        isCloseX={false}
        handleClose={(dados) => {
          if (dados) {
            const { index, produto: produto2, ...dados2 } = dados;

            if (index < 0) {
              let unidades = 0;
              let peso = 0;

              if (produto.unidade.tipo === POR_PESO_FIXO) {
                unidades = dados.qtde;
                peso = unidades * produto.peso_medio;
              } else if (produto.unidade.tipo === POR_UNIDADE) {
                unidades = dados.qtde;
                peso = 0;
              } else {
                unidades = 0;
                peso = dados.qtde;
              }

              addNewItem({
                unidades,
                peso,
                unitario: dados2.preco_compra,
                unitarioImposto: dados2.preco_compra_impostos,
                validade: dados2.validade,
                total: getTotal(peso, unidades, dados2.preco_compra, produto),
                cg: dados2.cg,
                dadosPrecificacao: dados2.dadosPrecificacao,
                nImpressoes: dados2.nImpressoes,
                nImpressoesCartazes: dados2.nImpressoesCartazes,
                validades: dados2.validades,
                unitarioPresumido: dados2.preco_compra_presumido,
                unitarioImpostoPresumido: dados2.preco_compra_imposto_presumido,
              });
            } else {
              let unidades = 0;
              let peso = 0;

              if (produto2.unidade.tipo === POR_PESO_FIXO) {
                unidades = dados.qtde;
                peso = unidades * produto2.peso_medio;
              } else if (produto2.unidade.tipo === POR_UNIDADE) {
                unidades = dados.qtde;
                peso = 0;
              } else {
                unidades = 0;
                peso = dados.qtde;
              }

              atualizarItemExistente({
                produto: produto2,
                peso: produto2.unidade.tipo === POR_UNIDADE ? 0 : peso,
                total: getTotalCompra(
                  peso,
                  unidades,
                  dados2.preco_compra_impostos,
                  produto2,
                ),
                unidades:
                  produto2.unidade.tipo === POR_PESO_VARIAVEL_SEM_UNIDADE
                    ? 0
                    : unidades,
                unitario: dados2.preco_compra,
                uidd: `${produto2.nome}${lotes.length}`,
                idIndicadorChange: -1,
                validade: dados2.validade,
                unitarioImposto: dados2.preco_compra_impostos,
                cg: dados2.cg,
                dadosPrecificacao: dados2.dadosPrecificacao,
                nImpressoes: dados2.nImpressoes,
                nImpressoesCartazes: dados2.nImpressoesCartazes,
                index,
                validades: dados2.validades,
                unitarioPresumido: dados2.preco_compra_presumido,
                unitarioImpostoPresumido: dados2.preco_compra_imposto_presumido,
                totalPresumido: getTotalCompra(
                  peso,
                  unidades,
                  dados2.preco_compra_presumido
                    ? dados2.preco_compra_imposto_presumido
                    : 0,
                  produto2,
                ),
              });
            }
            /*if (refSidebarInputs.current) {
              refSidebarInputs.current.valuesPrecificacao(dados);
            }*/
          }
        }}
        idCompra={idEdit}
        hasRepasseNaoUnitarioIPI={repasse_ipi > 0}
        hasRepasseNaoUnitarioFrete={repasse_frete > 0}
        hasRepasseNaoUnitarioST={repasse_st > 0}
        hasRepasseNaoUnitarioSeguros={repasse_seguros > 0}
        hasRepasseNaoUnitarioOutrasDespesas={repasse_outras_despesas > 0}
        tipo={tipo}
        handleClose2={(valorNegociadoComAIndustriaPresumido, dados, index) => {
          if (valorNegociadoComAIndustriaPresumido) {
            if (dados) {
              atualizarItemExistente({
                ...dados,
              });
            } else {
              const index2 = lotes.findIndex((item) => item.uidd === index);
              const item = lotes[index2];
              atualizarItemExistente({
                ...getDadosLoteBonificacao(
                  {
                    ...getDadosXmlItem(item.referencia_xml),
                    correspondencia: item.produto,
                  },
                  index,
                  valorNegociadoComAIndustriaPresumido,
                ),
              });
            }
          } else if (dados) {
            atualizarItemExistente({
              ...dados,
            });
          } else {
            const index2 = lotes.findIndex((item) => item.uidd === index);

            const item = lotes[index2];

            atualizarItemExistente({
              ...getDadosLoteBonificacao(
                {
                  ...getDadosXmlItem(item.referencia_xml),
                  correspondencia: item.produto,
                },
                index,
                0,
              ),
            });
          }
        }}
      />
      {/* <DialogoCarregarNota
        ref={refDialogoCarregarNota}
        handleClose={() => {}}
        handleSalvarItensFormatados={(data) => }
      /> */}
      <DialogoGrafico ref={refDialogoGrafico} />
      <DialogoFiltrosProduto
        isProdutosSomenteFornecedor={isProdutosSomenteFornecedor}
        isIncluiInativos={isIncluiInativos}
        ref={refDialogoFiltrosProduto}
        handleClose={updateFiltros}
      />
      <DialogoDetalhesItens ref={refDialogoDetalhesItens} />
      <DialogoDetalhes
        ref={refDialogoDetalhes}
        subTotalProdutos={subTotalProdutos}
        //total_repasse_frete={total_repasse_frete}
        //total_repasse_ipi={total_repasse_ipi}
        //total_repasse_outras_despesas={total_repasse_outras_despesas}
        //total_repasse_seguros={total_repasse_seguros}
        //total_repasse_st={total_repasse_st}
        repasse_frete={repasse_frete}
        repasse_ipi={repasse_ipi}
        repasse_outras_despesas={repasse_outras_despesas}
        repasse_seguros={repasse_seguros}
        repasse_st={repasse_st}
        subTotal={subTotal}
        lotes={lotes}
      />
    </>
  );
};

export default FrenteCompra;
