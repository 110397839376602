import React, { cloneElement, useRef } from 'react';
import {
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  Button,
} from 'react-admin';

import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EditIcon from '@material-ui/icons/Edit';

//import DialogoListaProdutos from '../../Components/DialogoListaProdutos';
import DialogoEdicaoEmMassa from './DialogoEdicaoEmMassa';
//import DialogoGrandesPesagens from './DialogoGrandesPesagens';
import DialogoRelatorioProdutosBalanca from './DialogoRelatorioProdutosBalanca';

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  enabledCreate,
  handleCriar = () => {},
  ...rest
}) => {
  const refDialogoRelatorio1 = useRef(null);
  const refDialogoRelatorio2 = useRef(null);
  const refDialogoEdicaoEmMassa = useRef(null);

  //const refDialogoConferenciaGrandesPesagens = useRef(null);
  const refDialogoRelatorioProdutosBalanca = useRef(null);

  /*function openNota1() {
    refDialogoRelatorio1.current.handleOpen();
  }*/

  /*function openNota2() {
    refDialogoRelatorio2.current.handleOpen();
  }*/

  function openEdicaoEmMassa() {
    refDialogoEdicaoEmMassa.current.handleOpen();
  }

  function openDialogoRelatorioProdutosBalanca() {
    refDialogoRelatorioProdutosBalanca.current.handleOpen();
  }

  /*function openConferenciaGrandesPesagens() {
    refDialogoConferenciaGrandesPesagens.current.handleOpen();
  }*/

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {/*<Button
        onClick={openConferenciaGrandesPesagens}
        label="Conferencia Grandes Pesagens"
      >
        <AssignmentIcon />
      </Button>*/}
      <Button
        onClick={openDialogoRelatorioProdutosBalanca}
        label="PDF Itens de Balança"
      >
        <AssignmentIcon />
      </Button>
      {/*<Button onClick={openNota1} label="Lista Produtos Ativos">
        <AssignmentIcon />
    </Button>*/}
      {/*<Button onClick={openNota2} label="Lista Produtos Todos">
        <AssignmentIcon />
  </Button>*/}
      <Button onClick={openEdicaoEmMassa} label="Edição em Massa">
        <EditIcon />
      </Button>
      <Button onClick={handleCriar} label="Novo">
        <AddIcon />
      </Button>

      {/*<DialogoListaProdutos
        ref={refDialogoRelatorio1}
        handleClose={() => {}}
        filters={{ ativo: true }}
        order={['nome', 'asc']}
/>*/}
      {/*<DialogoListaProdutos
        ref={refDialogoRelatorio2}
        handleClose={() => {}}
        filters={{}}
        order={['codigo_interno', 'asc']}
/>*/}
      <DialogoEdicaoEmMassa
        ref={refDialogoEdicaoEmMassa}
        handleClose={() => {}}
      />
      {/*<DialogoGrandesPesagens
        ref={refDialogoConferenciaGrandesPesagens}
        handleClose={() => {}}
      />*/}
      <DialogoRelatorioProdutosBalanca
        ref={refDialogoRelatorioProdutosBalanca}
      />
    </TopToolbar>
  );
};

export default ListActions;
