import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import { Font } from '@react-pdf/renderer';

import App from './App';
//import font from './assets/pacifico.ttf';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxPKTU1Kg.ttf',
});

Font.register({
  family: 'Roboto-Bold',
  src:
    'https://fonts.gstatic.com/s/roboto/v15/d-6IYplOFocCacKzxwXSOKCWcynf_cDxXwCLxiixG1c.ttf',
});

/* Font.register({
  family: 'Pacifico',
  src: font,
}); */

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register();

reportWebVitals();
