import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  DateField,
  ListContextProvider,
  useListContext,
  ListBase,
  ListToolbar,
  Pagination,
  Datagrid,
  useRefresh,
  Title,
  TextField,
} from 'react-admin';

import { Divider, Tabs, Tab, Card, makeStyles } from '@material-ui/core';

import { stylesMalote, STATUS_ATIVO, STATUS_INATIVO } from '../../utils';
import Actions from './ActionsList';
import ContainerDialogoImprimirCartazes from './ContainerDialogoImprimirCartazes';
import DialogoCreateApartirJornalAnterior from './DialogoCreateApartirJornalAnterior';
import DialogoCreateEdit from './DialogoCreateEdit';
import DialogoDeletar from './DialogoDeletarJornal';
import DialogoShow from './DialogoShow';
import ActionsField from './Fields/ActionsField';
import DataMaximaField from './Fields/DataMaximaField';
import DataMinimaField from './Fields/DataMinimaField';
import IdField from './Fields/IdField';
import MessageField from './Fields/MessageField';
import TempoRestanteField from './Fields/TempoRestanteField';
import Filter from './Filter';

const PostPanel = ({ record }) => (
  <div>
    {record.criador && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          marginBottom: '20px',
          border: '1px solid red',
          padding: '10px',
          borderRadius: '20px',
        }}
      >
        <h3 style={{ marginBottom: '10px', color: '#193bf299' }}>Criador:</h3>
        <div>
          <span
            style={{
              fontWeight: 'bold',
              marginRight: '10px',
            }}
          >
            Usuário:
          </span>
          <span>{record.criador ? record.criador.username : ''}</span>
        </div>
      </div>
    )}

    {record.atualizador && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          marginBottom: '20px',
          border: '1px solid red',
          padding: '10px',
          borderRadius: '20px',
        }}
      >
        <h3 style={{ marginBottom: '10px', color: '#193bf299' }}>
          Último a atualizar:
        </h3>
        <div>
          <span
            style={{
              fontWeight: 'bold',
              marginRight: '10px',
            }}
          >
            Usuário:
          </span>
          <span>{record.atualizador ? record.atualizador.username : ''}</span>
        </div>
      </div>
    )}

    {record.deletador && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          marginBottom: '20px',
          border: '1px solid red',
          padding: '10px',
          borderRadius: '20px',
        }}
      >
        <h3 style={{ marginBottom: '10px', color: '#193bf299' }}>Deletador:</h3>
        <div>
          <span
            style={{
              fontWeight: 'bold',
              marginRight: '10px',
            }}
          >
            Usuário:
          </span>
          <span>{record.deletador ? record.deletador.username : ''}</span>
        </div>
      </div>
    )}
  </div>
);

const useStylesDatagrid = makeStyles((theme) => stylesMalote);
const tabs = [
  { id: 'ativos', name: 'Ativos' },
  { id: 'inativos', name: 'Inativos' },
];

const MyList = ({ ...props }) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);
  const refresh = useRefresh();
  const classes = useStylesDatagrid();
  const refDialogoDelete = useRef(null);
  const refDialogoShow = useRef(null);
  const refDialogoCreateEdit = useRef(null);
  const refDialogoCreateApartirJornalAnterior = useRef(null);

  const refDialogoImprimirCartazes = useRef(null);

  function handleImprimirCartaz({ id }) {
    if (refDialogoImprimirCartazes.current) {
      refDialogoImprimirCartazes.current.handleOpen(id);
    }
  }

  function handleCriar() {
    if (refDialogoCreateEdit.current) {
      refDialogoCreateEdit.current.handleCreate();
    }
  }

  function handleEdit({ id }) {
    if (refDialogoCreateEdit.current) {
      refDialogoCreateEdit.current.handleEdit(id);
    }
  }

  function handleDeletar({ id }) {
    if (refDialogoDelete.current) {
      refDialogoDelete.current.handleOpen(id);
    }
  }

  function handleShow({ id }) {
    if (refDialogoShow.current) {
      refDialogoShow.current.handleOpen(id);
    }
  }

  function handleCriarApartirJornalAnterior({ id }) {
    if (refDialogoCreateApartirJornalAnterior.current) {
      refDialogoCreateApartirJornalAnterior.current.handleCreate(id);
    }
  }

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUS_ATIVO:
          setAtivos(ids);
          break;
        case STATUS_INATIVO:
          setInativos(ids);
          break;
        default:
          break;
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      if (setFilters) {
        setFilters(
          {
            ...filterValues,
            status: value === 'ativos' ? STATUS_ATIVO : STATUS_INATIVO,
          },
          displayedFilters,
        );
      }
    },
    [displayedFilters, filterValues, setFilters],
  );

  const handleClose = () => {
    refresh();
  };

  const postRowStyle = (record, index) => {
    const timeMinima = new Date(record.data_minima).getTime();
    const timeMaxima = new Date(record.data_maxima).getTime();
    const timeAtual = new Date().getTime();
    if (timeMinima < timeAtual && timeAtual < timeMaxima) {
      return {
        backgroundColor: '#7fe381',
      };
    }
    return {
      backgroundColor: 'white',
    };
  };

  return (
    <>
      <Title title="Jornais de Ofertas" />
      <ListToolbar
        filters={props.filters}
        actions={<Actions {...props} handleCriar={handleCriar} />}
        {...props}
      />
      <Card style={{ borderRadius: '20px' }} {...props}>
        <Divider />
        <Tabs
          variant="fullWidth"
          centered
          value={filterValues.status === STATUS_ATIVO ? tabs[0].id : tabs[1].id}
          indicatorColor="primary"
          onChange={handleChange}
        >
          {tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <div>
          {filterValues.status === STATUS_ATIVO && (
            <ListContextProvider value={{ ...listContext, ids: ativos }}>
              <Datagrid rowStyle={postRowStyle} {...props}>
                <IdField
                  source="id"
                  label="Id"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                />
                <MessageField
                  source="_"
                  label="Tipo"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                />
                <DataMinimaField
                  source="data_minima"
                  sortable={false}
                  label="Início"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                />
                <DataMaximaField
                  source="data_maxima"
                  sortable={false}
                  label="Vencimento"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                />
                <TempoRestanteField
                  source="data_maxima"
                  sortable={false}
                  label="Tempo Restante"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                />
                <TextField
                  source="numero_ofertas"
                  label="Nº Ofertas"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                />
                <ActionsField
                  source="lalala"
                  handleCriarApartirJornalAnterior={
                    handleCriarApartirJornalAnterior
                  }
                  handleEdit={handleEdit}
                  handleDeletar={handleDeletar}
                  handleShow={handleShow}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                  sortable={false}
                  handleImprimirCartaz={handleImprimirCartaz}
                />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.status === STATUS_INATIVO && (
            <ListContextProvider value={{ ...listContext, ids: inativos }}>
              <Datagrid {...props}>
                <IdField
                  source="id"
                  label="Id"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                />
                <DataMinimaField
                  source="data_minima"
                  sortable={false}
                  label="Início"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                />
                <DataMaximaField
                  source="data_maxima"
                  sortable={false}
                  label="Vencimento"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                />
                <TextField
                  source="numero_ofertas"
                  label="Nº Ofertas"
                  sortable={false}
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                />
                <ActionsField
                  source="lalala"
                  handleCriarApartirJornalAnterior={
                    handleCriarApartirJornalAnterior
                  }
                  handleEdit={handleEdit}
                  handleDeletar={handleDeletar}
                  handleShow={handleShow}
                  textAlign="right"
                  label="Ações"
                  headerClassName={classes.dataHeader}
                  cellClassName={classes.actionsCell}
                  sortable={false}
                  handleImprimirCartaz={handleImprimirCartaz}
                />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
        <TransacoesPagination />
      </Card>
      <DialogoDeletar ref={refDialogoDelete} handleClose={handleClose} />
      <DialogoCreateEdit ref={refDialogoCreateEdit} handleClose={handleClose} />
      <DialogoShow ref={refDialogoShow} />
      <DialogoCreateApartirJornalAnterior
        ref={refDialogoCreateApartirJornalAnterior}
        handleClose={handleClose}
      />
      <ContainerDialogoImprimirCartazes ref={refDialogoImprimirCartazes} />
    </>
  );
};

const ContainerMyList = ({ children, ...props }) => (
  <>
    <ListBase {...props}>
      <MyList {...props} />
    </ListBase>
  </>
);

const TransacoesPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25, 50]} {...props} />
);

const List2 = (props) => (
  <>
    <ContainerMyList
      {...props}
      expand={<PostPanel />}
      pagination={<TransacoesPagination />}
      perPage={50}
      filters={<Filter />}
      title="Jornais de Ofertas"
      filterDefaultValues={{
        status: STATUS_ATIVO,
      }}
      sort={{ field: 'id', order: 'DESC' }}
    />
  </>
);

export default List2;
