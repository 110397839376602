import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { getListAllAPI, getOneAPI } from '../../services';
import DialogoCartazesProdutos from './DialogoCartazesProdutos';

async function getConfig() {
  const data = await getOneAPI('config', 1, []);
  return data.data.titulo_cartaz;
}

const Container = forwardRef((props, ref) => {
  const isCarregando = useRef(false);
  const refDialogoCartazesProdutos = useRef(null);

  async function getProduto(idProduto) {
    try {
      const data = await getListAllAPI(
        'produtos',
        ['id', 'asc'],
        {
          ofertasProdutos: true,
          id: idProduto,
        },
        ['unidade'],
      );

      const header = await getConfig();

      if (refDialogoCartazesProdutos.current) {
        refDialogoCartazesProdutos.current.handleOpen(
          data.data.map((item) => ({ ...item, copias: 1 })),
          header,
        );
      }
    } catch (e) {
      isCarregando.current = false;
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(idProduto) {
      if (!isCarregando.current) {
        isCarregando.current = true;
        getProduto(idProduto);
      }
    },
  }));

  return (
    <DialogoCartazesProdutos
      ref={refDialogoCartazesProdutos}
      handleClose={() => {
        isCarregando.current = false;
      }}
    />
  );
});

export default Container;
