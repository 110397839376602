import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { api, getOneAPI } from '../../services';
import DialogoCartazesProdutos from '../Produtos/DialogoCartazesProdutos';

async function getConfig() {
  const data = await getOneAPI('config', 1, []);
  return data.data.titulo_cartaz;
}

const Container = forwardRef((props, ref) => {
  const isCarregando = useRef(false);
  const refDialogoCartazesProdutos = useRef(null);

  async function getProdutosCompra(idCompra) {
    try {
      const response = await api.get(`/get-produtos-compra/${idCompra}`);

      const header = await getConfig();

      if (refDialogoCartazesProdutos.current) {
        /* refDialogoCartazesProdutos.current.handleOpen(
          response.data.map((item) => ({ ...item, copias: 1 })),
        ); */

        refDialogoCartazesProdutos.current.handleOpen(response.data, header);
      }
    } catch (e) {
      isCarregando.current = false;
    }
  }

  useImperativeHandle(ref, () => ({
    handleOpen(idJornalOfertas) {
      if (!isCarregando.current) {
        isCarregando.current = true;
        getProdutosCompra(idJornalOfertas);
      }
    },
  }));

  return (
    <DialogoCartazesProdutos
      ref={refDialogoCartazesProdutos}
      handleClose={() => {
        isCarregando.current = false;
      }}
    />
  );
});

export default Container;
