import React from 'react';

import { Document } from '@react-pdf/renderer';

import ModeloDoisDigitos from './Pages/ModeloDoisDigitos';
import Modelo3Digitos from './Pages/ModeloTresDigitos';
import ModeloUmDigito from './Pages/ModeloUmDigito';

function montaPage(item, header) {
  const {
    descricao,
    descricao2,
    precoParteInteira,
    precoParteReal,
    unidade,
    labelCaixa,
    copias,
    mensagemPesoPadrao,
  } = item;

  const dados = {
    descricao,
    descricao2,
    precoParteInteira,
    precoParteReal,
    unidade,
    labelCaixa,
    mensagemPesoPadrao,
    header,
  };

  switch (precoParteInteira.length) {
    case 1:
      return <ModeloUmDigito {...dados} />;
    case 2:
      return <ModeloDoisDigitos {...dados} />;
    case 3:
      return <Modelo3Digitos {...dados} />;
    default:
      return <Modelo3Digitos {...dados} />;
  }
}

function getPages(item, header) {
  const { copias } = item;

  const page = montaPage(item, header);

  const arrayPages = [];

  for (let i = 0; i < copias; i++) {
    arrayPages.push(page);
  }

  return <>{arrayPages}</>;
}

const ListaProdutos = ({ itens, titulo, header }) => (
  <Document author="anderson" title={titulo}>
    {itens.map((item) => getPages(item, header))}
  </Document>
);

export default ListaProdutos;
